import React from "react";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  DotGroup,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import { IconButton } from "@material-ui/core";
import CustomSection from "../../../../../utils/Custom Section/section";
import CarouselSlide from "./Components/Slide/slide";
import styles from "./newCollection.module.scss";
//icons
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

export default function NewCollectionSection() {
  return (
    <CustomSection
      style={{
        backgroundColor: "#9d8e73",
        backgroundImage:
          "url(https://s3-alpha-sig.figma.com/img/60e7/e9fa/58de6a057941dd1452116d2ac0d67a88?Expires=1626048000&Signature=OO0fOUpsUgikScNjADCCagdTNkXmeqQ2o~ZtL8KR9Yti5FkyB8lk6oWrjCZ6Hd-AUnjlh8GodDnKURKAl83MpwHkVZ~HkB34NOd977C3uU7sKmO3oOlTIHbJpX1KGOprS8gE81gJ-SEaRPcUxsXyQvXl7Y0fRUnqTSYMI3sQ6jBy0s9lOIWyK3aHApVAQUAJPp9312uEaRyYI1XlNGtnkUhtC4QMf6KhFgkge2FVtOaJ2Vp4V3sH-v6VZUPnB5MgSGUvKKEep1ah6NQCKJ0beJER~ex3O88OAtsyW5d~3y~7cGnubJ6veZ7faavk-XjQrzmFKGKD98Gi9wB-qlBLGg__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA)",
        backgroundSize: "cover",
        backgroundRepeat: "repeat",
        paddingTop: "4rem",
      }}
    >
      <CarouselProvider
        naturalSlideWidth={100}
        totalSlides={2}
        infinite
        isIntrinsicHeight
      >
        <Slider>
          <Slide index={0}>
            <CarouselSlide>
              <div className={styles.sliderBtnDiv}>
                <ButtonBack className={styles.sliderBtn}>
                  <IconButton className={styles.iconBtn}>
                    <NavigateBeforeIcon />
                  </IconButton>
                </ButtonBack>
                <ButtonNext className={styles.sliderBtn}>
                  <IconButton className={styles.iconBtn}>
                    <NavigateNextIcon />
                  </IconButton>
                </ButtonNext>
              </div>
            </CarouselSlide>
          </Slide>
          <Slide index={1}>
            <CarouselSlide>
              <div className={styles.sliderBtnDiv}>
                <ButtonBack className={styles.sliderBtn}>
                  <IconButton className={styles.iconBtn}>
                    <NavigateBeforeIcon />
                  </IconButton>
                </ButtonBack>
                <ButtonNext className={styles.sliderBtn}>
                  <IconButton className={styles.iconBtn}>
                    <NavigateNextIcon />
                  </IconButton>
                </ButtonNext>
              </div>
            </CarouselSlide>
          </Slide>
        </Slider>
      </CarouselProvider>
    </CustomSection>
  );
}

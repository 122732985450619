import React from "react";
import styles from "./card.module.scss";
//icon

export default function DesignersCard() {
  const img =
    "https://images.pexels.com/photos/7137411/pexels-photo-7137411.png?auto=compress&cs=tinysrgb&dpr=2&w=500";
  return (
    <div className={styles.container}>
      <img src={img} alt='product' />
      <div className={styles.trends}>
        <span>New Fashion Trends</span>
        <span>₹3000</span>
      </div>
    </div>
  );
}

import React from "react";
import { Switch, Route } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
//Pages
import Home from "./Pages/Home-Page/home";
import MensWear from "./Pages/Mens-Wear/mensWear";
import Offer from "./Pages/Offer/offer";
import DesignersProductPage from "./Pages/Designers-Product-Page/designer-product-page";
import ProductDescription from "./Pages/Product-Description/productDescription";
import ProductBreakdown from "./Pages/Product-Breakdown/productBreakdown";
import CustomisedProductDetails from "./Pages/Customised-Product-Details/customisedProductDetails";
import Simulate from "./Pages/Simulate/simulate";
import AddMeasurement from "./Pages/Add-Measurement/addMeasurement";
import MyMeasurements from "./Pages/My-Measurements/myMeasurements";
import ChooseStandardSize from "./Pages/Choose-Standard-Size/chooseSize";
import AddMeasurementGender from "./Pages/Add-Measurement-Gender/gender";
import AddMeasurementBasicDetails from "./Pages/Add-Measurement-Basic-Details/basicDetails";
import AddMeasurementBodyMeasurement from "./Pages/Add-Measurement-body-measurement/bodyMeasurement";
import Measurement from "./Pages/Measurement/measurement";
import OrderSummary from "./Pages/Order-Summary/orderSummary";
import DeliveryAddress from "./Pages/Delivery Address/deliveryAddress";
import Payment from "./Pages/Payment/payment";
import Offers from "./Pages/Offers/offers";
import Orders from "./Pages/Orders/orders";
import AllOrders from "./Pages/All-Orders/allOrders";

//other-dev-pages
// import { AboutPage, DesignersHomePage } from "./Other-Dev-Content/pages";

function App() {
  return (
    <AnimatePresence exitBeforeEnter>
      <Switch>
        <Route exact path='/' component={Home} />
        <Route path='/mens-wear' component={MensWear} />
        <Route path='/offer' component={Offer} />
        <Route
          path='/designers-product-page'
          component={DesignersProductPage}
        />
        <Route path='/product-description' component={ProductDescription} />
        <Route path='/product-breakdown' component={ProductBreakdown} />
        <Route
          path='/customised-product-details'
          component={CustomisedProductDetails}
        />
        <Route path='/simulate' component={Simulate} />
        <Route path='/add-measurement' component={AddMeasurement} />
        <Route path='/my-measurements' component={MyMeasurements} />
        <Route
          path='/add-measurement-choose-standard-size'
          component={ChooseStandardSize}
        />
        <Route
          path='/add-measurement-gender'
          component={AddMeasurementGender}
        />
        <Route
          path='/add-measurement-basic-details'
          component={AddMeasurementBasicDetails}
        />
        <Route
          path='/add-measurement-body-measurement'
          component={AddMeasurementBodyMeasurement}
        />
        <Route path='/measurement' component={Measurement} />
        <Route path='/order-summary' component={OrderSummary} />
        <Route path='/delivery-address' component={DeliveryAddress} />
        <Route path='/payment' component={Payment} />
        <Route path='/offers' component={Offers} />
        <Route path='/all-orders' component={AllOrders} />
        <Route path='/orders' component={Orders} />
        {/* other dev pages */}
        {/* <Route path='/designers-home-page' component={DesignersHomePage} />
        <Route path='/about' component={AboutPage} /> */}
      </Switch>
    </AnimatePresence>
  );
}

export default App;

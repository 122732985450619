import React from "react";
import { Link } from "react-router-dom";
import styles from "./card.module.scss";
//icon
import Off50 from "../../../../../../../Images/icons/fiftyOff.svg";

export default function TopOffersCard() {
  const img =
    "https://s3-alpha-sig.figma.com/img/06df/53ea/36643e06cce33e865b3d7c0610d08f53?Expires=1627257600&Signature=NiLcM8YEtilFr0~W~LsMVZ-bKqAdRU2JjWej4x-TNwSZrRf5sqwb0OoLv7HeWwHvup02kzMOVXQMPirW3W~HAUSN0Z5qweLnvdWm4zdlExIbnUBXN-LgCnXwPjS~KHo83ZTSYvlx8a~JVIvgxIr2N3fAqNhJXp0KejaOpGlqdA3cBlxeXl0FzX1XlXWD5gPic2Zs0uPm-6~emZAv7uR4Ydi7bZTvkw3AXse0-SFWGmLGpO7JyL3u6g4rxUTY5-kK~qk7UCAErVKI3HAGhfOVeUVoxqn~1DVpr3BicsDSghEMwE07Qd17igDYa0Bx0QX1m6D5CA-H89dK6mMWbnM2kQ__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA";
  return (
    <div className={styles.container}>
      <img src={img} alt='product' />
      <div className={styles.productDetailsDiv}>
        <img src={Off50} alt='50% off' />
        <span>OFFER WEEK</span>
        <span>Shirts &amp; Trousers Designers</span>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tristique
          adipiscing.
        </p>

        <Link to='/'>REDEEM YOUR OFFER CARD</Link>
      </div>
    </div>
  );
}

import React, { useState, useRef } from "react";
import {
  FormControl,
  Select,
  MenuItem,
  IconButton,
  useMediaQuery,
  Drawer,
  List,
  SwipeableDrawer,
  ListItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import cx from "classnames";
import { Link, useLocation } from "react-router-dom";
import CustomSection from "../Custom Section/section";
import SideNavbar from "../Side-Navbar/sideNavbar";
import styles from "./header.module.scss";
//Icons
import PersonIcon from "../../Images/icons/person.svg";
import FavoriteIcon from "../../Images/icons/favorite.svg";
import BagIcon from "../../Images/icons/bag.svg";
import SearchIcon from "../../Images/icons/search.svg";
import HamMenuIcon from "../../Images/icons/hamMenu.svg";
import SearchDarkIcon from "../../Images/icons/searchDark.svg";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

export default function Header() {
  const location = useLocation();
  const [currency, setCurrency] = useState("INR");
  const [mouseEnter, setMouseEnter] = useState({
    newArrivals: false,
    men: false,
    women: false,
    kids: false,
    designers: false,
    contempory: false,
    offers: false,
    more: false,
  });
  const [mouseLeave, setMouseLeave] = useState({
    newArrivals: true,
    men: true,
    women: true,
    kids: true,
    designers: true,
    contempory: true,
    offers: true,
    more: true,
  });

  const handleMouseEnter = (value) => {
    setMouseEnter({
      ...mouseEnter,
      [value]: true,
    });
    setMouseLeave({
      ...mouseLeave,
      [value]: false,
    });
  };

  const handleMouseLeave = (value) => {
    setMouseEnter({
      ...mouseEnter,
      [value]: false,
    });
    setTimeout(() => {
      setMouseLeave({
        ...mouseLeave,
        [value]: true,
      });
    }, 200);
  };

  const tabView = useMediaQuery("(max-width:768px)");
  const mobileView = useMediaQuery("(max-width:550px)");

  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerOpen(open);
  };
  return (
    <CustomSection style={{ padding: mobileView && "0 1rem" }}>
      <div className={styles.firstContainer}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: mobileView ? "8px" : "21px",
          }}
        >
          {tabView && (
            <IconButton
              aria-label='menu'
              style={{ marginLeft: "-12px" }}
              onClick={() => setDrawerOpen(true)}
            >
              <img
                src={HamMenuIcon}
                alt='menu'
                className={styles.hamMenuIcon}
              />
            </IconButton>
          )}
          <span className={styles.logo}>LOGO</span>
        </div>
        {tabView && !mobileView && (
          <div className={styles.searchBarContainer}>
            <img src={SearchIcon} alt='search' />
            <input
              type='text'
              placeholder='Search for designers, brands and more'
            />
          </div>
        )}

        {mobileView && (
          <div>
            <IconButton aria-label='search'>
              <img
                src={SearchDarkIcon}
                className={styles.mobileViewSideIcons}
                alt='search'
              />
            </IconButton>
            <IconButton aria-label='my bag' style={{ marginRight: "-12px" }}>
              <img
                src={BagIcon}
                alt='my bag'
                className={styles.mobileViewSideIcons}
              />
            </IconButton>
          </div>
        )}
        {!tabView && (
          <div>
            <FormControl
              style={{
                width: "auto",
                boxShadow: "none",
              }}
            >
              <Select
                disableUnderline
                value={currency}
                onChange={(e) => setCurrency(e.target.value)}
              >
                <MenuItem selected value='INR'>
                  <b>India(₹)</b>
                </MenuItem>
                <MenuItem value='USD'>
                  <b>US($)</b>
                </MenuItem>
                <MenuItem value='JPY'>
                  <b>Japan(¥)</b>
                </MenuItem>
              </Select>
            </FormControl>
            <IconButton aria-label='my account'>
              <img src={PersonIcon} alt='my account' />
            </IconButton>
            <IconButton aria-label='favorites'>
              <img src={FavoriteIcon} alt='favorites' />
            </IconButton>
            <IconButton aria-label='my bag'>
              <img src={BagIcon} alt='my bag' />
            </IconButton>
          </div>
        )}
      </div>
      {!tabView && (
        <div className={styles.secondContainer}>
          <div className={styles.mainMenuContainer}>
            <span
              onMouseEnter={() => handleMouseEnter("newArrivals")}
              onMouseLeave={() => handleMouseLeave("newArrivals")}
              className={cx(styles.menuItem, styles.menuItem_newArrivals)}
            >
              New arrivals
            </span>
            <span
              onMouseEnter={() => handleMouseEnter("men")}
              onMouseLeave={() => handleMouseLeave("men")}
              className={cx(styles.menuItem, styles.menuItem_men)}
            >
              Men
            </span>
            <span
              onMouseEnter={() => handleMouseEnter("women")}
              onMouseLeave={() => handleMouseLeave("women")}
              className={cx(styles.menuItem, styles.menuItem_women)}
            >
              Women
            </span>
            <span
              onMouseEnter={() => handleMouseEnter("kids")}
              onMouseLeave={() => handleMouseLeave("kids")}
              className={cx(styles.menuItem, styles.menuItem_kids)}
            >
              Kids
            </span>
            <span
              onMouseEnter={() => handleMouseEnter("designers")}
              onMouseLeave={() => handleMouseLeave("designers")}
              className={cx(styles.menuItem, styles.menuItem_designers)}
            >
              Designers
            </span>
            <span
              onMouseEnter={() => handleMouseEnter("contemporary")}
              onMouseLeave={() => handleMouseLeave("contemporary")}
              className={cx(styles.menuItem, styles.menuItem_contempory)}
            >
              Contemporary
            </span>
            <span
              onMouseEnter={() => handleMouseEnter("offers")}
              onMouseLeave={() => handleMouseLeave("offers")}
              className={cx(styles.menuItem, styles.menuItem_offers)}
            >
              Offers
            </span>
            <span
              onMouseEnter={() => handleMouseEnter("more")}
              onMouseLeave={() => handleMouseLeave("more")}
              className={cx(styles.menuItem, styles.menuItem_more)}
            >
              More
            </span>
          </div>
          <div className={styles.verticalDivider} />
          <div className={styles.searchBarContainer}>
            <img src={SearchIcon} alt='search' />
            <input
              type='text'
              placeholder='Search for designers, brands and more'
            />
          </div>
        </div>
      )}

      <div
        style={{
          display: mouseEnter.newArrivals
            ? "block"
            : mouseLeave.newArrivals
            ? "none"
            : "",
        }}
      ></div>
      <SwipeableDrawer
        anchor={"left"}
        open={isDrawerOpen}
        onClose={toggleDrawer("left", false)}
        onOpen={toggleDrawer("left", true)}
        transitionDuration={600}
      >
        {location.pathname === "/orders" ||
        location.pathname === "/offers" ||
        location.pathname === "/all-orders" ? (
          <div className={styles.sideNavbarDiv}>
            <SideNavbar />
          </div>
        ) : (
          <List style={{ width: mobileView ? "80vw" : "40vw" }}>
            <ListItem style={{ display: "flex", alignItems: "center" }}>
              <FormControl
                style={{
                  width: "auto",
                  boxShadow: "none",
                }}
              >
                <Select
                  disableUnderline
                  value={currency}
                  onChange={(e) => setCurrency(e.target.value)}
                >
                  <MenuItem selected value='INR'>
                    <b>India(₹)</b>
                  </MenuItem>
                  <MenuItem value='USD'>
                    <b>US($)</b>
                  </MenuItem>
                  <MenuItem value='JPY'>
                    <b>Japan(¥)</b>
                  </MenuItem>
                </Select>
              </FormControl>
              <IconButton aria-label='my account'>
                <img src={PersonIcon} alt='my account' />
              </IconButton>
              <IconButton aria-label='favorites'>
                <img src={FavoriteIcon} alt='favorites' />
              </IconButton>
              <IconButton aria-label='my bag'>
                <img src={BagIcon} alt='my bag' />
              </IconButton>
            </ListItem>
            <ListItem style={{ height: "64px" }}>
              <Link to='/' className={cx(styles.links, styles.menuItem)}>
                New arrivals
              </Link>
            </ListItem>
            <ListItem>
              <Accordion className={styles.accordion}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon size='small' />}
                  className={styles.accordionSummary}
                >
                  <span className={styles.menuItem}>Men</span>
                </AccordionSummary>
                <AccordionDetails className={styles.accordionDetials}>
                  <div className={styles.subMenuItems}>
                    <Link>Indian fusion wear</Link>
                    <Link>western wear</Link>
                    <Link>Bottom wear</Link>
                    <Link>Bottom wear</Link>
                  </div>
                </AccordionDetails>
              </Accordion>
            </ListItem>
            <ListItem>
              <Accordion className={styles.accordion}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon size='small' />}
                  className={styles.accordionSummary}
                >
                  <span className={styles.menuItem}>Women</span>
                </AccordionSummary>
                <AccordionDetails className={styles.accordionDetials}>
                  <div className={styles.subMenuItems}>
                    <Link>Indian fusion wear</Link>
                    <Link>western wear</Link>
                    <Link>Bottom wear</Link>
                    <Link>Bottom wear</Link>
                  </div>
                </AccordionDetails>
              </Accordion>
            </ListItem>
            <ListItem>
              <Accordion className={styles.accordion}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon size='small' />}
                  className={styles.accordionSummary}
                >
                  <span className={styles.menuItem}>Kids</span>
                </AccordionSummary>
                <AccordionDetails className={styles.accordionDetials}>
                  <div className={styles.subMenuItems}>
                    <Link>Indian fusion wear</Link>
                    <Link>western wear</Link>
                    <Link>Bottom wear</Link>
                    <Link>Bottom wear</Link>
                  </div>
                </AccordionDetails>
              </Accordion>
            </ListItem>
            <ListItem>
              <Accordion className={styles.accordion}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon size='small' />}
                  className={styles.accordionSummary}
                >
                  <span className={styles.menuItem}>Designers</span>
                </AccordionSummary>
                <AccordionDetails className={styles.accordionDetials}>
                  <div className={styles.subMenuItems}>
                    <Link>Indian fusion wear</Link>
                    <Link>western wear</Link>
                    <Link>Bottom wear</Link>
                    <Link>Bottom wear</Link>
                  </div>
                </AccordionDetails>
              </Accordion>
            </ListItem>
            <ListItem style={{ height: "64px" }}>
              <Link to='/' className={cx(styles.links, styles.menuItem)}>
                Contempory
              </Link>
            </ListItem>
            <ListItem style={{ height: "64px" }}>
              <Link to='/' className={cx(styles.links, styles.menuItem)}>
                Offers
              </Link>
            </ListItem>
            <ListItem style={{ height: "64px" }}>
              <Link to='/' className={cx(styles.links, styles.menuItem)}>
                More
              </Link>
            </ListItem>
          </List>
        )}
      </SwipeableDrawer>
    </CustomSection>
  );
}
